var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"das_top_cont"},[_c('div',{staticClass:"history_box"},[_c('div',{staticClass:"accountright_cont_box"},[_vm._m(0),_c('div',{staticClass:"his_sel_box",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"sel_box computer_right_cont_box"},[_c('div',{staticClass:"title2"},[_vm._v("Language")]),_c('el-select',{attrs:{"placeholder":"Select your prefered language"},model:{value:(_vm.IndicatorsValType),callback:function ($$v) {_vm.IndicatorsValType=$$v},expression:"IndicatorsValType"}},_vm._l((_vm.type),function(item,index){return _c('el-option',{key:index,attrs:{"width":"400","label":item.type == '1'
                    ? 'Online data'
                    : item.type == '2'
                    ? 'Offline data'
                    : item.type,"value":item.type}})}),1)],1),_c('div',{staticClass:"sel_box computer_right_cont_box"},[_c('div',{staticClass:"title2",staticStyle:{"margin-top":"30px"}},[_vm._v("Location")]),_c('el-select',{attrs:{"placeholder":"Select your location"},model:{value:(_vm.IndicatorsValType),callback:function ($$v) {_vm.IndicatorsValType=$$v},expression:"IndicatorsValType"}},_vm._l((_vm.type),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.type == '1'
                    ? 'Online data'
                    : item.type == '2'
                    ? 'Offline data'
                    : item.type,"value":item.type}})}),1)],1)]),_c('div',{staticClass:"userSettingbottom"},[_vm._m(1),_c('div',{staticClass:"his_sel_box",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"flex_center_between_box sel_box switchbox"},[_c('span',[_vm._v("Email Notification")]),_c('el-switch',{attrs:{"active-value":"1","inactive-value":"0","active-color":"#13ce66"},model:{value:(_vm.switchto),callback:function ($$v) {_vm.switchto=$$v},expression:"switchto"}})],1),_c('div',{staticClass:"flex_center_between_box sel_box switchbox"},[_c('span',[_vm._v("Service Updates")]),_c('el-switch',{attrs:{"active-value":"1","inactive-value":"0","active-color":"#13ce66"},model:{value:(_vm.switchtotwo),callback:function ($$v) {_vm.switchtotwo=$$v},expression:"switchtotwo"}})],1),_c('div',{staticClass:"flex_center_between_box sel_box switchbox"},[_c('span',[_vm._v("Company News")]),_c('el-switch',{attrs:{"active-value":"1","inactive-value":"0","active-color":"#13ce66"},model:{value:(_vm.switchtotree),callback:function ($$v) {_vm.switchtotree=$$v},expression:"switchtotree"}})],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex_center_between_box"},[_c('h2',{staticClass:"history_box-head"},[_vm._v("Language and Location")]),_c('button',{staticClass:"btn_blue flex_center change_btn"},[_vm._v(" Save ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex_center_between_box"},[_c('h2',{staticClass:"history_box-head"},[_vm._v("Notification Setting")])])
}]

export { render, staticRenderFns }