<template>
  <div>
    <div class="das_top_cont">
      <div class="history_box">
        <div class="accountright_cont_box">
          <div class="flex_center_between_box">
            <h2 class="history_box-head">Language and Location</h2>
            <button class="btn_blue flex_center change_btn">
              <!-- <img src="../../assets/img/detail/btn_ic5.png" /> -->
              Save
            </button>
          </div>
          <div class="his_sel_box" style="width: 100%">
            <div class="sel_box computer_right_cont_box">
              <div class="title2">Language</div>
              <el-select
                v-model="IndicatorsValType"
                placeholder="Select your prefered language"
              >
                <el-option
                  v-for="(item, index) in type"
                  :key="index"
                  width="400"
                  :label="
                    item.type == '1'
                      ? 'Online data'
                      : item.type == '2'
                      ? 'Offline data'
                      : item.type
                  "
                  :value="item.type"
                ></el-option>
              </el-select>
            </div>
            <div class="sel_box computer_right_cont_box">
              <div class="title2" style="margin-top: 30px;">Location</div>
              <el-select
                v-model="IndicatorsValType"
                placeholder="Select your location"
              >
                <el-option
                  v-for="(item, index) in type"
                  :key="index"
                  :label="
                    item.type == '1'
                      ? 'Online data'
                      : item.type == '2'
                      ? 'Offline data'
                      : item.type
                  "
                  :value="item.type"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="userSettingbottom">
            <div class="flex_center_between_box">
              <h2 class="history_box-head">Notification Setting</h2>
            
            </div>
            <div class="his_sel_box" style="width: 100%">
                <div class="flex_center_between_box sel_box switchbox">
               <span>Email Notification</span>
                  <el-switch v-model="switchto" active-value="1" inactive-value="0" active-color="#13ce66"></el-switch>
                </div>
                 <div class="flex_center_between_box sel_box switchbox">
               <span>Service Updates</span>
                  <el-switch v-model="switchtotwo" active-value="1" inactive-value="0" active-color="#13ce66"></el-switch>
                </div>
                 <div class="flex_center_between_box sel_box switchbox">
               <span>Company News</span>
                  <el-switch v-model="switchtotree" active-value="1" inactive-value="0" active-color="#13ce66"></el-switch>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mixins1 } from "@/mixins/index";

export default {
  mixins: [mixins1],
  data() {
    return {
      value1: "",
      CategoryVal: "",
      type:[],
      IndicatorsValType:'',
      switchto:'0',
      switchtotwo:'1',
      switchtotree:'0',
      yourUsagelist: [
        {
          UserAccount: "Julian Chen@gmail.com",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
        {
          UserAccount: "Julian Chen@gmail.com",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
        {
          UserAccount: "Julian Chen@gmail.com",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc. Apple Inc. Apple Inc. Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
        {
          UserAccount: "Julian Chen@gmail.com",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc. Apple Inc. Apple Inc. Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },

        {
          UserAccount: "Julian Chen@gmail.com",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc. Apple Inc. Apple Inc. Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
        {
          UserAccount: "Julian Chen@gmail.com",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
        {
          UserAccount: "Ruling documents",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc. Apple Inc. Apple Inc. Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
        {
          UserAccount: "Ruling documents",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
        {
          UserAccount: "Ruling documents",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
      ],
      window: window,
    };
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {},

  methods: {
    apptablelist() {
      this.yourUsagelist.push(
        {
          UserAccount: "Ruling documents",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
        {
          UserAccount: "Ruling documents",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
        {
          UserAccount: "Ruling documents",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        },
        {
          UserAccount: "Ruling documents",
          Date: "2024-07-26  11:23:32",
          CompanyName: "Apple Inc.",
          UnitsUsed: "2",

          UsageType: "News Saction",
        }
      );
    },
  },
};
</script>
<style scoped>
.flex_center_between_box {
  display: flex;
  justify-content: space-between;
}
.form-email {
  display: flex;
  width: 100%;
}
.block-con-body-text {
  word-break: keep-all;
  text-align: justify;
  font-size: 1rem;
  color: #022955;
}

.btn_blue {
  padding: 0.7142857142857143rem 1.4285714285714286rem;
  background: #1290c9;
  border-radius: 0.5714285714285714rem;
  font-size: 1.2857142857142858rem;
  color: #ffffff;
  cursor: pointer;
}

.btn_blu {
  margin-right: 1.4285714285714286rem;
}

.btn_blue img {
  width: 1.2857142857142858rem;
  height: 1.2857142857142858rem;
  margin-right: 0.42857142857142855rem;
}
.sel_box {
  margin-right: 1.7857142857142858rem;
  width: 31.071428571428573rem;
}
.sel_box /deep/ .el-input__inner {
  border-color: #ccc;
  border-radius: 0.5714285714285714rem;
  font-size: 1.4285714285714286rem;
}
h6 {
  font-size: 1.2857142857142858rem;
  color: #1a2332;
  line-height: 1.5rem;
  margin-bottom: 0.7142857142857143rem;
  margin-top: 1.4285714285714286rem;
}
.userSettingbottom{
    margin-top: 2.142857142857143rem ;
    border-top: 0.07142857142857142rem solid #DDDDDD;
    padding-top: 2.142857142857143rem;
}
/deep/ .el-select {
 width: 100%;
}
.switchbox{
    margin-top: 2.142857142857143rem;
    font-size:1.4285714285714286rem ;
}
/deep/.el-switch__core {
  
    width: 4.714285714285714rem !important;
    height: 2.5714285714285716rem;
    border-radius: 1.2857142857142858rem;

}
/deep/ .el-switch__core:after{
        width: 2.2857142857142856rem;
    height: 2.142857142857143rem;
}
/deep/ .el-switch.is-checked .el-switch__core::after {
        margin-left: -2.4285714285714284rem;
}
.title2{
  font-size: 1.42857143rem;
  margin-bottom: 0.7142857142857143rem;
}
@media screen and (max-width: 1441px) {
  .btn_blue {
    font-size: 14px;
    width: max-content;
  }
}
</style>